import React, { useState, createContext, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";

export const TeamsContext = createContext();

axios.defaults.withCredentials = true;

export const TeamsContextProvider = ({ children }) => {
  const [teams, setTeams] = useState(null);
  const [team, setTeam] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isAddMoreStaffOpened, setIsAddMoreStaffOpened] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useState(false);
  const [currentStage, setCurrentStage] = useState(1);
  const [createTeamInfo, setCreateTeamInfo] = useState({
    team_name: "",
    servers_ids: [],
  });
  const [teamToEdit, setTeamToEdit] = useState(null);
  const [teamSuccess, setTeamSuccess] = useState("");

  const handleModalClose = () => {
    setIsInviteModalOpen(false);
    setCreateTeamInfo({ team_name: "", servers_ids: [] });
    setCurrentStage(1);
  };

  const setSuccessFunc = (successMessage) => {
    setTeamSuccess(successMessage);

    setTimeout(() => {
      setTeamSuccess("");
    }, 5000);
  };

  const fetchTeams = useCallback(() => {
    axios
      .get(
        process.env.REACT_APP_KNOWBIE_API_URL + "/teams/get-teams",
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      )
      .then((res) => {
        setTeams(res.data.teams);
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchTeam = useCallback((teamId) => {
    axios
      .get(
        process.env.REACT_APP_KNOWBIE_API_URL + `/teams/get-team/${teamId}`,
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      )
      .then((res) => {
        setTeam(res.data.team);
      })
      .catch((err) => console.log(err));
  }, []);

  const createTeam = (operatorId) => {
    axios
      .post(
        process.env.REACT_APP_KNOWBIE_API_URL + "/teams/create-team",
        { ...createTeamInfo, operatorId },
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      )
      .then((res) => {
        handleModalClose();
        setTeams(res.data.teams);
        setSuccessFunc("You’ve created a new team");
      })
      .catch((err) => console.log(err));
  };

  const deleteTeam = (id) => {
    axios
      .post(
        process.env.REACT_APP_KNOWBIE_API_URL + "/teams/delete-team",
        { teamId: id },
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      )
      .then((res) => {
        setIsDeleteModal(false);
        setTeamToEdit(null);
        setTeams(res.data.teams);
      })
      .catch((err) => console.log(err));
  };

  const renameTeam = (id, newTeamName) => {
    axios
      .post(
        process.env.REACT_APP_KNOWBIE_API_URL + "/teams/rename-team",
        { teamId: id, newTeamName },
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      )
      .then((res) => {
        setIsChangeNameModalOpen(false);
        setTeamToEdit(null);
        setTeams(res.data.teams);
        setSuccessFunc(`Your team name has been changed`);
      })
      .catch((err) => console.log(err));
  };

  const addTeamMembers = (id, newMembers) => {
    axios
      .post(
        process.env.REACT_APP_KNOWBIE_API_URL + "/teams/add-new-team-members",
        { teamId: id, newMembers: newMembers.map((el) => el.id) },
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      )
      .then((res) => {
        setIsAddMoreStaffOpened(false);
        setTeam(res.data.team);
        setSuccessFunc(
          `You’ve added ${newMembers.length} staff to ${res.data.team.team_name} Team`
        );
      })
      .catch((err) => console.log(err));
  };

  const removeTeamMember = (id, membersToDelete) => {
    axios
      .post(
        process.env.REACT_APP_KNOWBIE_API_URL + "/teams/remove-team-members",
        { teamId: id, membersToDelete },
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      )
      .then((res) => {
        setIsDeleteModal(false);
        setSelectedUsers([]);
        setTeam(res.data.team);
        setSuccessFunc(
          `You’ve deleted ${membersToDelete.length} staff from ${res.data.team.team_name} Team`
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <TeamsContext.Provider
      value={{
        teams,
        isInviteModalOpen,
        setIsInviteModalOpen,
        handleModalClose,
        currentStage,
        setCurrentStage,
        createTeamInfo,
        setCreateTeamInfo,
        createTeam,
        fetchTeams,
        isDeleteModal,
        setIsDeleteModal,
        teamToEdit,
        setTeamToEdit,
        deleteTeam,
        isChangeNameModalOpen,
        setIsChangeNameModalOpen,
        renameTeam,
        team,
        setTeam,
        fetchTeam,
        selectedUsers,
        setSelectedUsers,
        isAddMoreStaffOpened,
        setIsAddMoreStaffOpened,
        addTeamMembers,
        removeTeamMember,
        teamSuccess,
        setTeamSuccess,
        setSuccessFunc,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};
